import React from "react";

function LMS() {
  return (
    <div>
      Check if user is logged in, if not, then force user to login. If already
      logged in, then use SSO to log into LMS.
    </div>
  );
}

export default LMS;
