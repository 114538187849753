import React from 'react'

function Playground() {
  return (
    <div>
      This is Playground.
    </div>
  )
}

export default Playground
