const createRoomURL = "api/createRoom";
const endMeetingURL = "api/endMeeting";
const protectedRouteURL = "api/protected";
const oauthAuthorize = "api/oauth/authorize";
const oauthToken = "api/oauth/token";
const userInfoRouteURL = "api/userinfo";
const testRouteURL = "api/test";

/**************************************************************************************
 * ****
 * ****
 * **** Private Methods
 * ****
 * ****
 * ***********************************************************************************/
const login_local = async (username, clientID, redirectURI, state, password) => {
  const params = new URLSearchParams({
    response_type: "code", // Required by OAuth2
    client_id: clientID, // Replace with the actual client ID
    redirect_uri: redirectURI, // Set this to your frontend's callback route
    state: state,
    username: username,
    password: password,
  });

  try {
    const res = await fetch(`${oauthAuthorize}?${params.toString()}`, {
      method: "GET",
      credentials: "include", // Allows cookies to be sent for session management
    });

    console.log(`res.status: ${res.status}.`);

    // Check for failed authorization (non-200 status)
    if (!res.ok) {
      const errorText = await res.text(); // or res.json() if server sends JSON error
      console.error(`Authorization failed: ${errorText}`);
      return { error: "Authorization failed", details: errorText };
    }

    /*// Successful authorization - handle the redirect
    const redirectURL = res.url;
    console.log(`Redirecting to: ${redirectURL}`);
    //window.location.href = redirectURL;

    return { message: "Authorization successful" };*/
    const data = await res.json();
    console.log(`Authorization data: ${JSON.stringify(data)}.`);
    return data;
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    return null;
  }
};

const getToken_local = async (
  username,
  clientID,
  clientSecret,
  redirectURI,
  authorizationCode
) => {
  /*const data = {
    authorizationCode: authorizationCode,
  };*/
  try {
    const res = await fetch(oauthToken, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        code: authorizationCode,
        grant_type: "authorization_code",
        username: username,
        redirect_uri: redirectURI,
        client_id: clientID,
        client_secret: clientSecret,
      }),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`getToken data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not get token - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from getToken: ${error}`);
    return null;
  }
};

const getUserInfo_local = async () => {
  try {
    const res = await fetch(userInfoRouteURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(
          "ediscoolAccessToken"
        )}`,
        ContentType: "application/json",
      },
    });

    console.log(`res.status: ${res.status}.`);

    if (res) {
      const data = await res.json();
      console.log(`getUserInfo data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not get user info - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${userInfoRouteURL}: ${error}`);
    return null;
  }
};

const accessProtectedRoute_local = async () => {
  try {
    const res = await fetch(protectedRouteURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(
          "ediscoolAccessToken"
        )}`,
        ContentType: "application/json",
      },
    });

    console.log(`res.status: ${res.status}.`);

    if (res) {
      const data = await res.json();
      console.log(`accessProtectedRoute data: ${JSON.stringify(data)}.`);
      if (res.status === 200) {
        console.log("Access to protected route successful.");
        return {
          success: true,
          message: "Access to protected route successful.",
          data: data,
        };
      } else {
        console.error("Access to protected route failed.");
        return {
          success: false,
          message: "Access to protected route failed.",
          data: data,
        };
      }
    }
    console.error("Could not access protected route - return null");
    return null;
  } catch (error) {
    console.error(`Error fetching from ${protectedRouteURL}: ${error}`);
    return null;
  }
};

/*
const login_local = async (username, password) => {
  const params = new URLSearchParams({
    response_type: 'code', // Required by OAuth2
    client_id: 1, // Replace with the actual client ID
    redirect_uri: 'http://localhost:3001/callback', // Set this to your frontend's callback route
    username: username,
    password: password,
  });

  try {
    const res = await fetch(`${oauthAuthorize}?${params.toString()}`, {
      method: "GET",
      credentials: "include", // Allows cookies to be sent for session management
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`Authorization data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not authorize - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    return null;
  }
};
*/

const createRoom_local = async (
  meetingID,
  meetingName,
  moderatorPW,
  attendeePW
) => {
  const data = {
    meetingID: meetingID,
    meetingName: meetingName,
    moderatorPW: moderatorPW,
    attendeePW: attendeePW,
  };
  try {
    const res = await fetch(createRoomURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`createRoom data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("could not create room - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${createRoomURL}: ${error}`);
    return null;
  }
};

const endMeeting_local = async (meetingID, moderatorPW) => {
  const data = {
    meetingID: meetingID,
    moderatorPW: moderatorPW,
  };
  try {
    const res = await fetch(endMeetingURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`endMeeting data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("could not end meeting - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${endMeetingURL}: ${error}`);
    return null;
  }
};
/**************************************************************************************
 * ****
 * ****
 * **** Public Methods
 * ****
 * ****
 * ***********************************************************************************/

export const login_API = async (
  username,
  clientID,
  redirectURI,
  state,
  password,
  setLoginResponse
) => {
  let result;

  console.log(
    `This is login_API username: ${username}, password: ${password}.`
  );

  login_local(username, clientID, redirectURI, state, password).then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("login response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "login response is undefinied.";
    } else {
      result = data;
    }
    setLoginResponse(result);
  });
};

// get session token
export const getToken_API = async (
  username,
  clientID,
  clientSecret,
  redirectURI,
  authorizationCode,
  setGetTokensResponse
) => {
  let result;

  console.log(`This is getToken_API authorizationCode: ${authorizationCode}.`);

  getToken_local(
    username,
    clientID,
    clientSecret,
    redirectURI,
    authorizationCode
  ).then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("getToken response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "getToken response is undefinied.";
    } else {
      result = data;
    }
    setGetTokensResponse(result);
  });
};

export const getUserInfo_API = async (setGetUserInfoResponse) => {
  let result;

  console.log(`This is getUserInfo_API.`);
  getUserInfo_local().then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("getUserInfo response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "getUserInfo response is undefinied.";
    } else {
      result = data;
    }
    setGetUserInfoResponse(result);
  });
};

export const accessProtectedRoute_API = async (
  setGetProtectedRouteResponse
) => {
  let result;
  accessProtectedRoute_local().then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("accessProtectedRoute response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "accessProtectedRoute response is undefinied.";
    } else {
      result = data;
    }
    setGetProtectedRouteResponse(result);
  });
};

export const testBackend_API = async (setTestBackendResponse) => {
  let result;

  console.log(`This is testBackend_API.`);

  fetch(testRouteURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      console.log(`res.status: ${res.status}.`);
      if (res) {
        return res.json();
      } else {
        console.error("Could not get test backend - return null");
        return null;
      }
    })
    .then((data) => {
      if (data === null || typeof data === "undefined") {
        console.error("testBackend response is undefinied.");
        result.returncode = "FAILED";
        result.errorMessage = "testBackend response is undefinied.";
      } else {
        result = data;
      }
      setTestBackendResponse(result);
    })
    .catch((error) => {
      console.error(`Error fetching from ${testRouteURL}: ${error}`);
    });
};

export const createRoom_API = async (
  meetingID,
  meetingName,
  moderatorPW,
  attendeePW,
  setCreateRoomResponse
) => {
  let result;

  console.log(
    `This is createRoom_API meetingID: ${meetingID}, meetingName: ${meetingName}, moderatorPW: ${moderatorPW}, attendeePW: ${attendeePW}.`
  );

  createRoom_local(meetingID, meetingName, moderatorPW, attendeePW).then(
    (data) => {
      if (data === null || typeof data === "undefined") {
        console.error("createRoom response is undefinied.");
        result.returncode = "FAILED";
        result.errorMessage = "createRoom response is undefinied.";
      } else {
        result = data;
      }
      setCreateRoomResponse(result);
    }
  );
};

export const endMeeting_API = async (
  meetingID,
  moderatorPW,
  setEndMeetingResponse
) => {
  let result;

  console.log(
    `This is endMeeting_API meetingID: ${meetingID}, moderatorPW: ${moderatorPW}.`
  );

  endMeeting_local(meetingID, moderatorPW).then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("endMeeting response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "endMeeting response is undefinied.";
    } else {
      result = data;
    }
    setEndMeetingResponse(result);
  });
};
