import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
//import { Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
//import BBBHandles from "./pages/BBBHandles";
import MainSections from "./pages/MainSections";
import Playground from "./pages/Playground";
import LMS from "./pages/LMS";
import Login from "./pages/Login";

function App() {
  useEffect(() => {
    console.log(`Mode of operation: ${process.env.REACT_APP_MODE}`);
    console.log(`Compilation time: ${process.env.REACT_APP_COMPILATION_TIME}`);
  }, []);

  return (
    <Router>
      <div className="app_container">
        <div>
          This is EdIsCool frontend application. Compilation time:{" "}
          {process.env.REACT_APP_COMPILATION_TIME}
        </div>
        <MainSections />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lms" element={<LMS />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/login" element={<Login />} />
          {/*<Route path="/bbb" element={<BBBHandles />} />*/}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
