import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import classes from "./page.module.css";

function MainSections() {
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();

  const startPlayground = () => {
    navigate("/playground");
  };

  const goToLMS = () => {
    navigate("/lms");
  };

  const logIn = () => {
    navigate("/login");
  };

  return (
    <div>
      <MyButton
        variant={"primary"}
        title={"EdIsCool Playground"}
        onClickHandler={startPlayground}
        disabled={isBusy}
      />
      <MyButton
        variant={"primary"}
        title={"EdIsCool LMS"}
        onClickHandler={goToLMS}
        disabled={isBusy}
      />
      <MyButton
        variant={"primary"}
        title={"Log In"}
        onClickHandler={logIn}
        disabled={isBusy}
      />
    </div>
  );
}

export default MainSections;
